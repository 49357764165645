import Cookies from 'js-cookie';
import axios from 'axios';

export default class MeerClickTracker {
    constructor(analyticsClass) {
        this.analytics = analyticsClass;
        this.meerclickSiteId = this.analytics.config.meerclick;
    }

    initialize() {
        this.analytics.log('Meerclick initialized');
    }

    pageview(callback) {
        let gaCid = null;

        if (typeof this.analytics.gaClient !== 'undefined' && this.analytics.gaClient.uaId != null) {
            gaCid = Cookies.get('_ga');
        }

        axios.get(`https://www.keuze.nl/api/mc/v1/view/${this.meerclickSiteId}`, {
            params: {
                channel: this.analytics.channel,
                url: this.analytics.currentPageUrl(true),
                session: this.analytics.sessionId,
                ga_cid: gaCid,
                referrer: document.referrer,
            },
        }).then((result) => {
            const { tracking } = result.data;

            if (tracking !== null) {
                this.analytics.setViewTrackingId(tracking);
            }
        }).catch((error) => {
            this.analytics.log('meerclick error', error);

            if (typeof callback === 'function') {
                this.analytics.setViewTrackingId('m_error_fb_' + Math.random());
                callback(true);
            }
        }).finally(() => {
            if (typeof callback === 'function') {
                callback(false);
            }
        });
    }

    event(eventName, eventData) {
        if (eventName !== 'click' && eventName !== 'outclick') {
            this.analytics.log('Meerclick: ignoring event', eventName);
            return;
        }

        const mcTrackData = {
            type: 'click',
            label: `${eventData.label}`,
            href: `${eventData.url}`,
            rel: '',
            stork_campaign: `${eventData.stork_campaign}`,
            stork_link: `${eventData.stork_link}`,
            product_type: `${eventData.product_type}`,
            product_id: `${eventData.product_id}`,
            conversion_value: `${eventData.conversion_value}`,
        };

        this.analytics.log('Meerclick mcTrackData', mcTrackData);

        axios.post(`https://www.keuze.nl/api/mc/v1/event?view=${this.analytics.trackingId}`, mcTrackData).catch((error) => {
            this.analytics.log('meerclick error', error);
        });
    }
}
