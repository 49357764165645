/**
 * Check whether this URL is a stork link (must start with /meer-informatie)
 * @param urlPath
 * @returns {boolean}
 */
function isStorkLink(urlPath) {
    return urlPath.startsWith('/meer-informatie/');
}

/**
 * Check whether this is an internal outclick (e.g., /energie/vergelijken/energiedirect/7?postcode=1111AB&nr=11)
 * @param urlPath
 * @returns {boolean}
 */
function isInternalOutClick(urlPath) {
    const internalOutClickRegex = /^\/(energie|internet)\/vergelijken\/[a-z0-9-]+\/[0-9]/gm;

    return internalOutClickRegex.test(urlPath);
}

/**
 * Link tracker class, this class ensures that all click interactions are tagged and attributed correctly.
 * This class handles the following type of links and clicks:
 *
 * - Button clicks - this logs just the button label and a 'click' event
 * - Link clicks - this triggers a system which checks the type of link
 *
 * The following link types are processed:
 * - Internal link - link to other page within the same domain
 * - Anchor link - link to other anchor on the _same page_
 * - Stork link - a link to /meer-informatie. The stork campaign and material is also logged as separate attribute.
 *   data-mc-* attributes and conversion value are also logged.
 * - Outclick - a link to an external page. If set, the data-mc- attributes and the conversion is logged.
 */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["findPageSection", "findDataAttributes"] }] */
export default class LinkTracker {
    constructor(analyticsClass) {
        this.analytics = analyticsClass;
    }

    initialize() {
        document.body.addEventListener('click', (event) => {
            this.handleMouseEvent(event);
        });

        // Add tracking param to api-....whitelabeled.nl links
        document.body.addEventListener('click', (event) => {
            const { tagName } = event.target;
            let anchorElement;

            if (tagName === 'a') {
                anchorElement = event.target;
            } else {
                anchorElement = event.target.closest('a');
            }

            if (anchorElement == null) {
                return;
            }

            let url = anchorElement.getAttribute('href');

            if (url == null || url.startsWith('/') || url.startsWith('#')) {
                return;
            }

            // Check whether we need to add tracking param:
            const regex = /api-[a-z]+\.(whitelabeled|vergelijkgroep|keuze)\.nl\//;

            if (!regex.test(url)) {
                return;
            }

            const urlObject = new URL(url);

            urlObject.searchParams.set('t', this.analytics.trackingId);

            url = urlObject.toString();

            event.preventDefault();
            window.open(url);
        });
    }

    /**
     * Handle a mouse event (click, mousedown, touchstart, etc.)
     * @param event
     */
    handleMouseEvent(event) {
        // Find a tags:
        const { tagName } = event.target;

        if (tagName === 'a') {
            this.trackLinkEvent(event.type, event.target);
            return;
        }
        if (tagName === 'button') {
            this.trackButtonEvent(event.type, event.target);
            return;
        }

        // Find a parent element which is an 'a':
        const parentAnchor = event.target.closest('a');

        if (parentAnchor !== null) {
            this.trackLinkEvent(event.type, parentAnchor);
        } else {
            // Find a button then?
            const parentButton = event.target.closest('button');

            if (parentButton !== null) {
                this.trackButtonEvent(event.type, parentButton);
            }
        }
    }

    trackButtonEvent(eventType, element) {
        this.analytics.log('Observed click on <button> tag');

        const label = element.innerText;
        this.analytics.event('click', {
            type: 'button',
            label,
            ...this.enrichElementData(element),
        });
    }

    /**
     * Track a link event
     * @param eventType Event type (click, mousedown, etc.)
     * @param element DOMElement
     */
    trackLinkEvent(eventType, element) {
        // Determine whether we should track this element at all:
        this.analytics.log('Observed click on link');

        const enrichedElementData = this.enrichElementData(element);

        if (element.hasAttribute('href')) {
            const href = element.getAttribute('href');
            let label = element.innerText;

            if (typeof label === 'undefined') {
                label = null;
            } else {
                label = label.trim();
            }

            // Check the kind of link:
            if (href.startsWith('#')) {
                this.trackAnchorClick(href, label, enrichedElementData);
            } else if (isStorkLink(href)) {
                this.trackStorkLink(href, label, element, enrichedElementData);
            } else if (isInternalOutClick(href)) {
                this.trackOutClick(href, label, element, enrichedElementData);
            } else if (href.startsWith('/') && !href.startsWith('//')) { // TODO: or starts with letter and doesn't contain :
                this.trackInternalLink(href, label, enrichedElementData);
                // Ignore the next line for eslint (it thinks this is a eval and eval is evil)
                // eslint-disable-next-line no-script-url
            } else if (href.startsWith('javascript:')) {
                this.analytics.log('Ignore javascript: URL');
            } else if (href.startsWith('tel:')) {
                this.analytics.log('Ignore tel: URL');
            } else {
                // Link is apparently fully qualified, translate to URL:
                let url;
                try {
                    url = new URL(href);
                } catch (exception) {
                    this.analytics.log('Invalid URL');
                    return;
                }

                // Check whether host is the same:
                if (url.hostname === document.location.hostname) {
                    // Treat as normal internal link/

                    // Hash (anchor) is set and document URL is the same?
                    if (url.hash !== '' && url.pathname === document.location.pathname) {
                        this.trackAnchorClick(url.hash, label, enrichedElementData);
                        // Is stork link?
                    } else if (isStorkLink(url.pathname)) {
                        this.trackStorkLink(url.pathname, label, element, enrichedElementData);
                    } else if (isInternalOutClick(url.pathname)) {
                        this.trackOutClick(url.pathname, label, element, enrichedElementData);
                    } else {
                        this.trackInternalLink(url.pathname + url.search, label, enrichedElementData);
                    }
                } else {
                    // Must be an outclick to an external domain
                    this.trackOutClick(href, label, element, enrichedElementData);
                }
            }
        } else {
            this.analytics.log('No href, ignoring click');
        }
    }

    /**
     * Track an internal anchor click
     * @param anchor
     * @param label
     * @param extraData
     */
    trackAnchorClick(anchor, label, extraData) {
        this.analytics.event('click', {
            type: 'anchor',
            anchor,
            label,
            page_url: this.analytics.currentPageUrl(),
            page_title: document.title,
            ...extraData,
        });
    }

    /**
     * Track an internal link
     * @param href
     * @param label
     * @param extraData
     */
    trackInternalLink(href, label, extraData) {
        this.analytics.event('click', {
            type: 'internal',
            url: href,
            label,
            ...extraData,
        });
    }

    /**
     * Track a Stork outclick (this method calls trackOutClick internally)
     * @param url
     * @param label
     * @param element
     * @param extraData
     */
    trackStorkLink(url, label, element, extraData) {
        let storkParams = {};
        const storkMatch = url.match(/meer-informatie\/([a-z0-9-]+)\/([a-z0-9-]+)/i);

        if (storkMatch !== null && storkMatch[1].length > 0 && storkMatch[2].length > 0) {
            storkParams = {
                stork_campaign: storkMatch[1],
                stork_link: storkMatch[2],
            };
        }

        storkParams = {
            ...storkParams,
            ...extraData,
        };

        this.trackOutClick(url, label, element, storkParams);
    }

    /**
     * Track an outclick
     * @param url
     * @param label
     * @param element
     * @param extraParams
     */
    trackOutClick(url, label, element, extraParams) {
        // Check whether this element has meerclick attributes: data-mc-cval, data-mc-pid, data-mc-pt, etc.
        let conversionValue = null;
        let productType = null;
        let productId = null;
        let provider = null;

        if (element.hasAttribute('data-mc-cval')) {
            conversionValue = parseFloat(element.getAttribute('data-mc-cval'));
        }

        if (element.hasAttribute('data-mc-pt')) {
            productType = element.getAttribute('data-mc-pt');
        }

        if (element.hasAttribute('data-mc-pid')) {
            productId = element.getAttribute('data-mc-pid');
        }
        if (element.hasAttribute('data-mc-provider')) {
            provider = element.getAttribute('data-mc-provider');
        }
        this.analytics.event('outclick', {
            url,
            label,
            conversion_value: conversionValue,
            product_type: productType,
            product_id: productId,
            provider,
            ...extraParams,
        });
    }

    enrichElementData(element) {
        return {
            header: this.findNearestHeader(element),
            component: this.findParentComponent(element),
            section: this.findPageSection(element),
            page_url: this.analytics.currentPageUrl(false),
            page_url_query: this.analytics.currentPageUrl(true),
            page_title: document.title,
            ...this.findDataAttributes(element),
        };
    }

    findDataAttributes(element) {
        const dataAttributes = { ...element.dataset };

        // Remove 'data-product', if it exists (it is usually filled with JSON)
        if (dataAttributes.product !== undefined) {
            delete dataAttributes.product;
        }

        return dataAttributes;
    }

    // This method find a header within the current block of the element. It prioritizes h1, then h2, then h3, then fake headers (with 'heading-element' class)
    findNearestHeader(element) {
        let header = null;

        // const result = document.evaluate('.//div', element, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
        let result = document.evaluate('./ancestor::div[@data-block-key]//h1', element, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
        if (result.singleNodeValue !== null) {
            header = result.singleNodeValue.textContent;
        }

        if (header == null) {
            result = document.evaluate('./ancestor::div[@data-block-key]//h2', element, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
            if (result.singleNodeValue !== null) {
                header = result.singleNodeValue.textContent;
            }
        }

        if (header == null) {
            result = document.evaluate('./ancestor::div[@data-block-key]//h3', element, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
            if (result.singleNodeValue !== null) {
                header = result.singleNodeValue.textContent;
            }
        }

        if (header == null) {
            result = document.evaluate('./ancestor::div[@data-block-key]//*[contains(@class,"heading-element")]', element, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
            if (result.singleNodeValue !== null) {
                header = result.singleNodeValue.textContent;
            }
        }

        if (header === null) {
            this.analytics.log('No header found');
        }

        return header;
    }

    // This method find a header within the current block of the element. It prioritizes h1, then h2, then h3, then fake headers (with 'heading-element' class)
    findParentComponent(element) {
        let component = null;

        const result = document.evaluate('./ancestor::div[@data-component-key]', element, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
        if (result.singleNodeValue !== null) {
            const { classList } = result.singleNodeValue;
            for (let i = 0; i < classList.length; i += 1) {
                if (classList[i].includes('-component')) {
                    component = classList[i];
                }
            }
        }

        if (component === null) {
            this.analytics.log('No component name found');
        }

        return component;
    }

    findPageSection(element) {
        if (element.closest('nav') !== null) {
            return 'nav';
        }

        if (element.closest('header') !== null) {
            return 'header';
        }

        if (element.closest('footer') !== null) {
            return 'footer';
        }

        return 'main';
    }
}
