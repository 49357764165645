// Function for tagging events in Google Analytics, normally this is exposed globally:
function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
}

/**
 * Google Analytics tracking class
 */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["disableAdPersonalizationSignals"] }] */
export default class GoogleAnalyticsTracker {
    constructor(analytics) {
        this.analytics = analytics;

        this.ga4Initialized = false;
        this.ga4Id = null;

        // Needed for Google Analytics
        window.dataLayer = window.dataLayer || [];
    }

    /**
     * Initialize Google Analytics
     */
    initialize() {
        gtag('js', new Date());

        GoogleAnalyticsTracker.setDefaultConsentStatus();

        // Check personalization consent:
        if (this.analytics.allowPersonalization === false) {
            this.disableAdPersonalizationSignals();
        }

        this.initializeGa4();
    }

    static setDefaultConsentStatus() {
        // See https://developers.google.com/tag-platform/devguides/consent for more documentation with regard to the
        // 'consent' tag
        // Default state is to allow
        gtag('consent', 'default', {
            ad_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            analytics_storage: 'granted',
            functionality_storage: 'granted',
            personalization_storage: 'granted',
            security_storage: 'granted',
            wait_for_update: 500,
        });
    }

    /**
     * Initialize Google Analytics 4:
     */
    initializeGa4() {
        if (typeof this.analytics.config.ga4 === 'undefined') {
            this.analytics.log('No GA4 ID, not initializing GA4');
            return;
        }

        this.ga4Id = this.analytics.config.ga4;

        // Initialize GA4 in debug mode when analytics is in debug mode.
        // This enables the DebugView which is useful in debugging tracking problems
        if (this.analytics.debugMode) {
            // Debug mode
            gtag('config', this.ga4Id, {
                debug_mode: true,
                transport_type: 'beacon',
            });
            this.analytics.log('GA4 initialized in debug mode', this.ga4Id);
        } else {
            // eslint-disable-next-line no-undef
            gtag('config', this.ga4Id, { transport_type: 'beacon' });
            this.analytics.log('GA4 initialized', this.ga4Id);
        }

        this.ga4Initialized = true;
    }

    /**
     * Log event to Google Analytics
     * @param eventName
     * @param eventData
     */
    event(eventName, eventData) {
        // TODO: better cleanup/filtering
        if (eventName === 'pageview') {
            // Pageviews are logged automatically
            this.analytics.log(`GA: ignoring event "${eventName}"`);
        }

        // Log event to GA4:
        if (this.ga4Initialized) {
            const ga4EventData = {
                send_to: this.ga4Id,
                ...eventData,
            };

            this.analytics.log('GA4 event', eventName, ga4EventData);
            gtag('event', eventName, ga4EventData);
        }
    }

    disableAdPersonalizationSignals() {
        // https://support.google.com/analytics/answer/9050852?hl=nl
        // Disable ad personalization:
        gtag('set', 'allow_ad_personalization_signals', false);

        gtag('consent', 'update', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            personalization_storage: 'denied',
        });
    }

    virtualPageView(event) {
        if (this.ga4Initialized) {
            gtag('event', 'page_view', {
                page_title: event.title,
                page_location: window.location,
                send_to: this.ga4Id,
            });
        }
    }
}
