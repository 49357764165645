import posthog from 'posthog-js';

function removeUrlParams(url) {
    if (url === null || url.indexOf('?') === -1) return url;

    const urlParts = url.split('?');

    return urlParts[0];
}

/**
 * Mixpanel tracking integration
 */
export default class PostHog {
    /**
     * Constructor
     * @param analyticsClass Analytics class
     */
    constructor(analyticsClass) {
        this.analytics = analyticsClass;

        this.initialized = false;
        this.posthogToken = null;
        this.posthogClient = posthog;
    }

    /**
     * Initialize Posthog
     */
    initialize() {
        if (typeof this.analytics.config.ph === 'undefined') {
            this.analytics.log('No PostHog ID, not initializing Posthog');
            return;
        }

        this.posthogToken = this.analytics.config.ph;

        this.analytics.log('Initializing Posthog', this.posthogToken);

        this.posthogClient.init(this.posthogToken, {
            api_host: 'https://www.keuze.nl/api/ph/',
            person_profiles: 'always',
            custom_campaign_params: ['channel'],
            sanitize_properties(properties, eventName) {
                if (eventName === '$pageview') {
                    // Remove URL params from URL properties
                    if (properties.$current_url) {
                        properties.current_url_params = properties.$current_url;
                        properties.$current_url = removeUrlParams(properties.$current_url);
                    }
                }

                return properties;
            },
            disable_session_recording: true,
        });

        this.initialized = true;
        this.analytics.log('Posthog initialized');

        if (this.analytics.sessionId !== null) {
            this.updateSessionTracking();
        }
    }

    /**
     * Log an event
     * @param eventName
     * @param eventData
     */
    event(eventName, eventData) {
        if (this.initialized) {
            const phEventData = {
                ...eventData,
                mc_session: this.analytics.sessionId,
                mc_view: this.analytics.trackingId,
            };

            this.analytics.log('Posthog event', eventName, phEventData);
            this.posthogClient.capture(eventName, phEventData);
        }
    }

    updateSessionTracking() {
        if (this.initialized) {
            // Set super properties
            posthog.register({
                mc_view: this.analytics.trackingId,
                mc_session: this.analytics.sessionId,
            });

            const userProperties = {
                channel: this.analytics.channel,
                utm_campaign: this.analytics.channel,
            };

            if (this.analytics.channelGroup !== null) {
                userProperties.channel_group = this.analytics.channelGroup;

                // Also set UTM medium for PostHog
                userProperties.utm_campaign = this.analytics.channelGroup;

                if (this.analytics.channel.startsWith('adwords_')) {
                    userProperties.utm_source = 'adwords';
                } else if (this.analytics.channel.startsWith('bing_')) {
                    userProperties.utm_source = 'bing';
                } else if (this.analytics.channel.startsWith('fb_')) {
                    userProperties.utm_source = 'facebook';
                }
            }

            if (this.analytics.channel != null 
                && (this.analytics.channel.startsWith('adwords_') 
                || this.analytics.channel.startsWith('pd_') 
                || this.analytics.channel.startsWith('bing_') 
                || this.analytics.channel.startsWith('meta_') 
                || this.analytics.channel.startsWith('fb_'))) {
                userProperties.channel_paid = true;
                userProperties.utm_medium = 'cpc';
            } else {
                userProperties.channel_paid = false;
            }

            const userPropertiesOnce = {
                start_url: this.analytics.currentPageUrl(false),
                start_url_query: this.analytics.currentPageUrl(true),
            };

            this.analytics.log('Set PostHog user properties', userProperties, userPropertiesOnce);
            posthog.identify(this.analytics.sessionId, userProperties, userPropertiesOnce);
        }
    }

    trackPageView() {
        if (this.initialized) {
            this.analytics.log('Posthog page view');
            this.posthogClient.capture('$pageview', {
                $current_url: this.analytics.currentPageUrl(false),
                mc_session: this.analytics.sessionId,
                mc_view: this.analytics.trackingId,
            });
        }
    }

    startSessionRecording() {
        if (this.initialized) {
            this.analytics.log('Posthog start session recording');
            this.posthogClient.startSessionRecording();
        }
    }

    stopSessionRecording() {
        if (this.initialized) {
            this.analytics.log('Posthog stop session recording');
            this.posthogClient.stopSessionRecording();
        }
    }
}
